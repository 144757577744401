/* Dashboard.css */

#img-block-alystar {
    background: url('../../img/header-bg.jpg');
    height: 200px;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 140px; /* Ajustez cette valeur en fonction de la hauteur de votre top-bar */
    left: 10%; /* Laissé pour le side-menu */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
  }
  
  #img-block-alystar h4,
  #img-block-alystar p {
    color: #e6e6e6;
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 0.5;
  }
  
  #img-block-alystar h4 {
    font-size: 45px;
  }

  .dashboard {
    padding-left: 250px; /* Largeur de la sidebar */
    height: 100%;
  }
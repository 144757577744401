/* css/LoginForm.css */

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Nouveau */
  background-color: transparent;
}

.logo-container {
  margin-bottom: 20px; /* Espace entre le logo et le formulaire */
}

.login-form {
  background: #ffffff3a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  display: flex;
  flex-direction: column;
}

.login-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: rgb(219, 219, 219);
}

.login-form input {
  margin-bottom: 15px;
  padding: 5px;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 4px;
}

.login-form button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form a {
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.login-form a:hover {
  text-decoration: underline;
}

.error-message {
  margin-top: 10px;
  color: red;
  font-weight: bold;
}

/* Header.css */

.App-header {
    position: relative;
    z-index: 1;
}

.header {
    height: auto;
    width: 100%;
    background-color: #e6e6e6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3; /* Assurez-vous que le z-index soit supérieur à celui du Sidebar */
    display: flex; /* Ajout de flexbox pour utiliser les propriétés align-items et align-self */
    align-items: center; /* Centrer verticalement les éléments enfants */
}

.header-logo {
    width: 180px;
    margin-left: 5px; /* Ajout de "px" après le nombre */
    padding: 5px;
    align-self: flex-start; /* Aligner le logo au début du conteneur */
}

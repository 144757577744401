/* App.css */


body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: black;
}

.App {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.canvas-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.App-header {
  position: relative;
  z-index: 1;
}





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    height: 40vmin;
    pointer-events: none;
    margin-bottom: 20px; /* Add some space between the logo and the form */
  }
}


.App-link {
  color: #61dafb;
}

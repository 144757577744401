/* Sidebar.css */
.sidebar {
    width: 250px;
    height: 100%;
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2; /* Assurez-vous que le z-index soit inférieur à celui du header */
    padding-top: 150px; /* height of header */
  }
  
  .sidebar h2 {
    color: white;
    text-align: center;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    text-decoration: none;
    text-transform: none;
  }
  
  .sidebar li {
    padding: 10px;
    text-align: left;
    padding-left: 25px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;

  }
  
  .sidebar li img {
    vertical-align: middle;
    color: blue;
    padding: 5px; /* Ajoutez un espace autour de l'icône si nécessaire */
    border-radius: 50%; /* Pour créer un cercle */
  }

  .sidebar a {
    text-decoration: none;
    color: inherit; /* Assurez-vous que la couleur du texte reste inchangée */
}
  
  .sidebar li:hover {
    background-color: #555;
  }

